<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">
      [小白专区]问卷调查，游戏试玩，应用体验等，简单有趣好做
    </p>
    <div>
      <div class="title">【工作内容】</div>
      <p class="works">
        <span>1、问卷调查，文字抄写，在线答题，游戏试玩，视频观看等</span>
        <span> 2、在家工作即可，无地点要求，时间自由 </span>
        <span>3、按单结算</span>
      </p>
    </div>
    <div>
      <div class="title">【报名须知】</div>
      <p class="psd">1、不限男女，不限学历</p>
    </div>
    <div>
      <div class="title">【薪资待遇】</div>
      <p class="psd">200-500/天</p>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/wjtc.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="title">【免责声明】</div>
      <p class="msg">
        <span>1、涉及到交纳会费、看淘宝淘气值、花呗等相关的内容都是诈骗。</span>
        <span>2、未标明有费用的岗位，涉及到收费的都可联系客服反馈。</span>
        <span>3、请大家仔细辨别相关招聘内容，谨防诈骗</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    width: 100vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
